import { graphql } from 'gatsby';
import React from 'react';

export default props => {
  const { data } = props;
  const { name } = data.item.data;

  return (
    <article>
      <div>
        <h1>{name}</h1>
      </div>
    </article>
  );
};

export const query = graphql`
  query SingleItemQuery($slug: String!) {
    item: airtable(data: { slug: { eq: $slug } }) {
      data {
        name
      }
    }
  }
`;
